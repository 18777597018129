import Re30 from "../assets/Re30.JPG"

export const testimonialsData = [
  {
    image: Re30,
    review:
      "I'm thankful and excited for the new journey at CrossFit 111, and let's transform our lives.",
    name: 'Re300 ',
    status : '- Founder CrossFit 111 Affiliate'
  }
];
