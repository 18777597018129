import React from 'react'
import './Creator.css'

export const Creator = () => {
  return (
<div className="yahya">
 <div className="copyright">
  © CrossFit 111. All Right Reserved
 </div>
 <div className="linked">
          <div>Created by 
          </div><div className="linked-in"><a href='https://www.linkedin.com/in/yahya-adel-7b2539222/' target='_blank' rel = "noreferrer"> Yahya Adel</a></div>
          </div>
        </div>
  )
}

