import React from 'react'

export const Map = () => {
  return (
    <div>
     <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13806.009123963297!2d31.6258466!3d30.1084377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d05b07a172efddc!2sCrossFit%20111!5e0!3m2!1sen!2seg!4v1661107931793!5m2!1sen!2seg" width="100%" height="450" style={{border:"0"}} 
     allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}
